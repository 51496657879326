<template>
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>

        <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
            <el-form-item prop="title" label="会员名称">
                <el-input size="small" v-model="formData.title" placeholder="会员名称"></el-input>
            </el-form-item>

            <el-form-item label="背景图片" prop="phone">
                <file-upload-img :url.sync="formData.bg_image"></file-upload-img>
                <span>(60 * 60)</span>
            </el-form-item>

            <el-form-item label="介绍" prop="describe">
                <el-input type="textarea" rows="3" placeholder="请输入内容" v-model="formData.describe" maxlength="500"
                          show-word-limit></el-input>
            </el-form-item>

            <el-form-item label="排序" prop="sort">
                <el-input-number v-model="formData.sort" :min="0" label="排序"></el-input-number>
            </el-form-item>

            <el-form-item label=" ">
                <el-button size="small" type="" @click="cancel">取消</el-button>
                <el-button size="small" type="primary" @click="save">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {addMember, editMember, detail} from './api'
import FileUploadImg from "@/components/uploader/uploader-img.vue";

export default {
    name: 'AddOrEdit',
    components: {FileUploadImg},


    data() {
        return {
            options: [],
            formData: {
                title: '',
                describe: '',
                bg_image: '',
                sort: 0
            },

            rules: {
                title: [{required: true, message: '会员名称必填', trigger: 'blur'}],
            }
        }
    },

    mounted() {
        if (this.$route.params.id) this.getAdminDetail()
    },

    methods: {
        getUploadUrl({fileUrl}) {
            this.formData.bg_image = fileUrl
        },
        save() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    if (this.formData.id) {
                        // 编辑
                        await editMember(this.formData).then(() => {
                            this.$message.success('编辑成功')
                            this.$router.back()
                        })
                    } else {
                        // 新增
                        await addMember(this.formData).then(() => {
                            this.$message.success('添加成功')
                            this.$router.back()
                        })
                    }
                } else {
                    return false
                }
            })
        },
        async getAdminDetail() {
            this.formData = await detail(this.$route.params.id)
        },
        cancel() {
            this.$router.back(-1)
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 500px;

        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
